import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { LOCK_CARD_CONFIRMATION_MODAL_WIDTH } from 'variables';
import { Input, Body } from '@sumup/circuit-ui';
import { LockCardModalContent } from 'components/Account/AccountStyled';

const LockCardConfirmationModal = ({
  paymentsBlocked,
  cardToken,
  onClose,
  onConfirm,
}) => {
  const comment = useRef('');

  const handleCommentChange = ({ target: { value } }) => {
    comment.current = value;
  };

  return (
    <Modal
      modalInfo={{
        width: LOCK_CARD_CONFIRMATION_MODAL_WIDTH,
        title: paymentsBlocked
          ? 'Unlock this card?'
          : 'Lock this card for internal audit?',
        confirmText: paymentsBlocked ? 'Unlock card' : 'Lock card',
        cancelText: 'Cancel',
        confirmButtonProps: {
          destructive: true,
        },
        centered: true,
        content: (
          <LockCardModalContent>
            <Body noMargin size="one">
              {paymentsBlocked
                ? 'The merchant will be able to make transactions with this card again.'
                : "The merchant won't be able to make any transactions with this card until it is unlocked again."}
            </Body>
            <Input
              noMargin
              placeholder="Comment (optional)"
              label="Comment"
              hideLabel
              onChange={handleCommentChange}
            />
          </LockCardModalContent>
        ),
        onConfirm: () =>
          onConfirm({
            cardToken,
            action: paymentsBlocked ? 'unblock_card' : 'block_card',
            comment: (comment || {}).current || '',
          }),
      }}
      onClose={onClose}
    />
  );
};

LockCardConfirmationModal.defaultProps = {
  cardToken: null,
};

LockCardConfirmationModal.propTypes = {
  paymentsBlocked: PropTypes.bool.isRequired,
  cardToken: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default LockCardConfirmationModal;

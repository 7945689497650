import React from 'react';
import PropTypes from 'prop-types';
import { Body, Select } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { map, keys } from 'lodash';
import {
  FRAUD_TYPE_MAP,
  FRAUD_SUB_TYPE_MAP,
} from 'components/Transactions/constants';

const Content = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    > label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      :not(:last-of-type) {
        margin-bottom: ${theme.spacings.giga};
      }

      > div {
        width: 100%;
      }
    }
  `
);

const SubTitle = styled(Body)(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacings.byte} 0 ${theme.spacings.giga};
  `
);

export const MarkAsFraudModalContent = ({
  onFraudTypeChange,
  onFraudSubtypeChange,
}) => (
  <Content>
    <SubTitle noMargin size="one">
      Card transactions to be reported to Mastercard
    </SubTitle>
    <Select
      placeholder="—"
      label="Fraud type"
      onChange={onFraudTypeChange}
      noMargin
    >
      {map(keys(FRAUD_TYPE_MAP), (code) => (
        <option key={code} value={code}>
          {FRAUD_TYPE_MAP[code]}
        </option>
      ))}
    </Select>
    <Select
      placeholder="—"
      label="Fraud sub-type"
      onChange={onFraudSubtypeChange}
      noMargin
    >
      {map(keys(FRAUD_SUB_TYPE_MAP), (code) => (
        <option key={code} value={code}>
          {FRAUD_SUB_TYPE_MAP[code]}
        </option>
      ))}
    </Select>
  </Content>
);

MarkAsFraudModalContent.propTypes = {
  onFraudTypeChange: PropTypes.func.isRequired,
  onFraudSubtypeChange: PropTypes.func.isRequired,
};

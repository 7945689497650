import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, Spinner, Headline, useModal, spacing } from '@sumup/circuit-ui';
import moment from 'moment';
import { ACTIONS, APPS, DATE_FORMATS, SORT_ORDER_DESCENDING } from 'variables';
import DataGrid from 'components/DataGrid';
import Empty from 'components/Empty';
import {
  CARD_DATA_CLEARING_BILLING_AMOUNT,
  TOTAL_AMOUNT,
  INTERNAL_IDENTIFIER,
  CARD_DATA_CLEARING_AMOUNT,
  TRANSACTION_CAN_CANCEL,
  TRANSACTION_CANCELLATION,
  TRANSACTION_CAN_CHARGEBACK,
  TRANSACTION_CHARGEBACK,
  CREATED_AT,
  TRANSACTION_CAN_MARK_AS_FRAUD,
  CARD_DATA_FIELD,
  CLEARING_DATE_FIELD,
} from 'components/Transaction/fieldsConfig';
import { useHttpClient } from 'hooks';
import { getTransaction } from 'api';
import sortGrid from 'services/sortGrid';
import Modal from 'components/Modal';
import {
  TransactionDetails,
  TransactionDetailsSection,
  ActionsSection,
  Clearings,
  ClearingsCount,
  TransactionDetailsWrapper,
  SpinnerWrapper,
  TransactionDetailsSectionWrapper,
  StyledButton,
  SubHeading,
} from './TransactionDetailsModalStyled';
import { CLEARINGS_NON_NUMERIC_FIELDS_MAP, columns } from './columns';
import MarkAsFraudModal from '../PopoverCell/components/MarkAsFraudModal';

const {
  accountcardTransactionsCancel,
  accountcardTransactionsChargeback,
  accountcardTransactionsFraud,
} = ACTIONS[APPS.merchant];

const Divider = styled('div')(
  ({ theme }) => css`
    width: 24px;
    height: 1px;
    background: ${theme.colors.n300};
    transform: rotate(90deg);
  `
);

const TransactionDetailsModal = ({
  txId,
  onCancelTransaction,
  onChargebackTransaction,
  onMarkAsPotentialFraud,
  hasActionAccess,
}) => {
  const [clearingsLoading, setClearingsLoading] = useState(false);
  const [clearings, setClearings] = useState([]);
  const [clearingsSort, setClearingsSort] = useState({
    [CLEARING_DATE_FIELD.name]: SORT_ORDER_DESCENDING,
    fieldName: CLEARING_DATE_FIELD.name,
  });

  const httpClient = useHttpClient();
  const { setModal } = useModal();

  const txData = (clearings || [])[0] || {};
  const txCardData = txData[CARD_DATA_FIELD.name];
  const txDate = txData[CREATED_AT.name];
  const internalIdentifier = txData[INTERNAL_IDENTIFIER.name];
  const txAmount = txData[TOTAL_AMOUNT.name];
  const txTotalCleared = (txCardData || {})[CARD_DATA_CLEARING_AMOUNT.name];
  const txTotalBilled = (txCardData || {})[
    CARD_DATA_CLEARING_BILLING_AMOUNT.name
  ];
  const cancelDisabled = txCardData && !txCardData[TRANSACTION_CAN_CANCEL.name];
  const chargebackDisabled =
    txCardData && !txCardData[TRANSACTION_CAN_CHARGEBACK.name];
  const markAsFraudDisabled =
    txCardData && !txCardData[TRANSACTION_CAN_MARK_AS_FRAUD.name];

  const getTransactionWrapped = useCallback(
    () =>
      getTransaction({ txId, httpClient })
        .then(({ data }) => {
          setClearingsLoading(false);

          setClearings(
            sortGrid({
              data,
              name: CLEARING_DATE_FIELD.name,
              getValue: CLEARING_DATE_FIELD.getValue,
              order: SORT_ORDER_DESCENDING,
              nonNumericFields: CLEARINGS_NON_NUMERIC_FIELDS_MAP,
            })
          );
        })
        .catch(() => {
          setClearings([]);
          setClearingsLoading(false);
        }),
    [httpClient, txId]
  );

  useEffect(() => {
    setClearingsLoading(true);
    getTransactionWrapped();
  }, [getTransactionWrapped]);

  const handleClearingsSort = ({ name, order, getValue }) => {
    setClearings(
      sortGrid({
        data: clearings,
        name,
        getValue,
        order,
        nonNumericFields: CLEARINGS_NON_NUMERIC_FIELDS_MAP,
      })
    );

    setClearingsSort({
      [name]: order,
      fieldName: order,
    });
  };

  const handleMarkAsFraud = ({ fraudTypeCode, fraudSubtypeCode }) =>
    onMarkAsPotentialFraud({
      txId,
      fraudTypeCode,
      fraudSubtypeCode,
    });

  const handleMarkAsFraudButtonClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <MarkAsFraudModal onConfirm={handleMarkAsFraud} onClose={onClose} />
      ),
    });
  };

  const handleChargebackButtonClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            cancelText: 'Cancel',
            centered: true,
            ...TRANSACTION_CHARGEBACK.confirmationModalInfo,
            disabled: false,
          }}
          onConfirm={() =>
            onChargebackTransaction({
              txId,
            })
          }
          onClose={onClose}
        />
      ),
    });
  };

  const handleCancelButtonClick = () => {
    setModal({
      closeButtonLabel: 'Close',
      onClose: () => {},
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            cancelText: 'Cancel',
            centered: true,
            ...TRANSACTION_CANCELLATION.confirmationModalInfo,
            disabled: false,
          }}
          onConfirm={() =>
            onCancelTransaction({
              txId,
            })
          }
          onClose={onClose}
        />
      ),
    });
  };

  const hasMarkAsFraudAccess = hasActionAccess({
    action: accountcardTransactionsFraud,
  });
  const hasChargebackAccess = hasActionAccess({
    action: accountcardTransactionsChargeback,
  });
  const hasCancelAccess = hasActionAccess({
    action: accountcardTransactionsCancel,
  });

  return (
    <>
      <TransactionDetailsWrapper>
        <Body noMargin size="one" variant="highlight">
          Transaction {txId || ''}
        </Body>
        <TransactionDetails>
          <TransactionDetailsSectionWrapper>
            <TransactionDetailsSection>
              <SubHeading noMargin size="two" variant="highlight">
                ORIGINAL TRANSACTION DATE:
              </SubHeading>
              <Body noMargin variant="highlight" size="one">
                {txDate ? (
                  moment(txDate).format(
                    DATE_FORMATS.SHORT_MONTH_DAY_YEAR_SHORT_TIME
                  )
                ) : (
                  <Empty>—</Empty>
                )}
              </Body>
            </TransactionDetailsSection>
            <TransactionDetailsSection>
              <SubHeading noMargin size="two" variant="highlight">
                INTERNAL IDENTIFIER:
              </SubHeading>
              <Body noMargin variant="highlight" size="one">
                {internalIdentifier != null ? (
                  internalIdentifier
                ) : (
                  <Empty>—</Empty>
                )}
              </Body>
            </TransactionDetailsSection>
            <TransactionDetailsSection>
              <SubHeading noMargin size="two" variant="highlight">
                AMOUNT:
              </SubHeading>
              <Body noMargin variant="highlight" size="one">
                {txAmount ? (
                  `${txAmount.amount / 100}${txAmount.currency}`
                ) : (
                  <Empty>—</Empty>
                )}
              </Body>
            </TransactionDetailsSection>
            <TransactionDetailsSection>
              <SubHeading noMargin size="two" variant="highlight">
                TOTAL CLEARED:
              </SubHeading>
              <Body noMargin variant="highlight" size="one">
                {txTotalCleared ? (
                  `${txTotalCleared.amount / 100}${txTotalCleared.currency}`
                ) : (
                  <Empty>—</Empty>
                )}
              </Body>
            </TransactionDetailsSection>
            <TransactionDetailsSection>
              <SubHeading noMargin size="two" variant="highlight">
                TOTAL BILLED:
              </SubHeading>
              <Body noMargin variant="highlight" size="one">
                {txTotalBilled ? (
                  `${txTotalBilled.amount / 100}${txTotalBilled.currency}`
                ) : (
                  <Empty>—</Empty>
                )}
              </Body>
            </TransactionDetailsSection>
          </TransactionDetailsSectionWrapper>
          {txCardData && (
            <ActionsSection>
              {hasMarkAsFraudAccess && (
                <>
                  <StyledButton
                    color="alert"
                    disabled={markAsFraudDisabled}
                    onClick={handleMarkAsFraudButtonClick}
                  >
                    Mark as potential fraud
                  </StyledButton>
                  {(hasChargebackAccess || hasCancelAccess) && <Divider />}
                </>
              )}
              {hasChargebackAccess && (
                <StyledButton
                  color="alert"
                  disabled={chargebackDisabled}
                  css={spacing({ right: 'byte' })}
                  onClick={handleChargebackButtonClick}
                >
                  Chargeback transaction
                </StyledButton>
              )}
              {hasCancelAccess && (
                <StyledButton
                  color="alert"
                  disabled={cancelDisabled}
                  onClick={handleCancelButtonClick}
                >
                  Cancel transaction
                </StyledButton>
              )}
            </ActionsSection>
          )}
        </TransactionDetails>
      </TransactionDetailsWrapper>
      <Clearings>
        <Headline noMargin as="h2" size="two">
          Clearings
          {!clearingsLoading && (
            <ClearingsCount>({clearings.length})</ClearingsCount>
          )}
        </Headline>
        {clearingsLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <DataGrid
            columns={columns}
            dataSource={clearings}
            noResultsText="No clearings"
            sortable
            sort={clearingsSort}
            hasActionAccess={hasActionAccess}
            onSort={handleClearingsSort}
          />
        )}
      </Clearings>
    </>
  );
};

TransactionDetailsModal.propTypes = {
  txId: PropTypes.string.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  onChargebackTransaction: PropTypes.func.isRequired,
  onMarkAsPotentialFraud: PropTypes.func.isRequired,
  hasActionAccess: PropTypes.func.isRequired,
};

export default TransactionDetailsModal;

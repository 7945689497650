import React from 'react';
import { Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Alert, ArrowRight, Refresh } from '@sumup/icons';
import moment from 'moment';
import {
  CUSTOM_FIELD_TYPES,
  DATE_FORMATS,
  FIELD_TYPES,
  CANCEL_TRANSACTION_CONFIRMATION_MODAL_WIDTH,
  APPS,
  ACTIONS,
  SECTIONS,
} from 'variables';
import {
  BANK_TRANSACTION_TYPE_MAP,
  BANK_TRANSACTION_STATUS_MAP,
  MESSAGE_TYPE_MAP,
  TRANSACTION_STATUS_MAP,
  TRANSACTION_TYPE_MAP,
  TRANSACTION_TYPES,
  INBOUND_BANK_TRANSFER_LABEL,
  OUTBOUND_BANK_TRANSFER_LABEL,
  ISSUE_A_RETURN_ACTION,
  CUSTOM_FIELD_NAMES,
} from 'components/Transactions/constants';
import { ReactComponent as Return } from 'components/Transactions/assets/issue-return.svg';
import { ReactComponent as Statement } from 'assets/statement.svg';
import { ReactComponent as PotentialFraud } from 'assets/potential-fraud.svg';
import { ReactComponent as EditFraud } from 'assets/edit.svg';
import { ReactComponent as UnmarkFraud } from 'assets/unmark.svg';
import { ReactComponent as RequestTransfer } from 'assets/request-transfer.svg';
import { SELF_TRANSFER } from 'services/constants';
import {
  isSelfTransferReturn,
  isBalanceToBalance,
} from 'services/transactions';
import { StyledPopoverOptionTitle } from './TransactionStyled';

export const CARD_DATA_FIELD = {
  name: 'card_data',
};

export const BANK_DATA_FIELD = {
  name: 'bank_data',
};

const getCardDataFieldValue = (transaction, name) =>
  (transaction[CARD_DATA_FIELD.name] || {})[name] || null;

const getCardDataDpan = (transaction, name) => {
  const dpan = getCardDataFieldValue(transaction, name);

  return dpan ? `DPAN: ${dpan.substr(-4)}` : '';
};

export const CLEARING_DATE_FIELD = {
  label: 'Date',
  name: 'clearing_date',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name]
      ? transaction[CARD_DATA_FIELD.name][name]
      : null,
};

export const CONVERSION_RATE_BILLING_FIELD = {
  label: '',
  name: 'conversion_rate_billing',
  renderAs: CUSTOM_FIELD_TYPES.CURRENCY_CONVERSION,
};

export const CARD_DATA_CLEARING_CONVERSATION_RATE_BILLING = {
  label: 'Cleared rate billing',
  name: 'clearing_conversion_rate_billing',
  renderAs: FIELD_TYPES.FLOAT,
  fractionDigits: 3,
  getValue: getCardDataFieldValue,
};

export const INTERNAL_IDENTIFIER = {
  label: 'Internal identifier',
  name: 'internal_identifier',
};

export const CARD_DATA_CANCELLED = {
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name] && transaction[CARD_DATA_FIELD.name][name]
      ? 'Cancelled'
      : '',
  name: 'cancelled',
  textStyle: {
    fontWeight: 'bold',
  },
};

export const CARD_DATA_CHARGED_BACK = {
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name] && transaction[CARD_DATA_FIELD.name][name]
      ? 'Charged back'
      : '',
  name: 'chargedback',
  textStyle: {
    fontWeight: 'bold',
  },
};

export const CARD_DATA_MARKED_AS_FRAUD = {
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name] && transaction[CARD_DATA_FIELD.name][name]
      ? 'Potential fraud'
      : '',
  name: 'marked_as_fraud',
  textStyle: {
    fontWeight: 'bold',
  },
};

export const TRANSACTION_CAN_CANCEL = {
  name: 'can_cancel',
};

export const TRANSACTION_CAN_CHARGEBACK = {
  name: 'can_chargeback',
};

export const TRANSACTION_CAN_MARK_AS_FRAUD = {
  name: 'can_mark_as_fraud',
};

export const CREATED_AT = {
  label: 'Date',
  name: 'created_at',
  sortName: 'DATE',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

export const TRANSACTION_IDENTIFIER = {
  label: 'Token',
  name: 'identifier',
  copyable: true,
  cropText: true,
};

export const TRANSACTION_FRAUD_REPORT = {
  label: 'Fraud',
  name: 'fraud_report',
};

export const TRANSACTION_FRAUD_REPORT_IDENTIFIER = {
  label: 'Fraud',
  name: 'id',
};

export const TRANSACTION_FRAUD_REPORT_TYPE = {
  name: 'type',
};

export const TRANSACTION_FRAUD_REPORT_SUBTYPE = {
  name: 'subtype',
};

export const TRANSACTION_FRAUD_REPORT_STATUS = {
  name: 'status',
};

export const TRANSACTION_FRAUD_REPORT_DATE = {
  name: 'tx_reported_date',
};

export const TRANSACTION_FRAUD_REPORT_RECOVERED_AMOUNT = {
  name: 'recovered_amount',
};

export const TRANSACTION_FRAUD_REPORT_REFERENCE = {
  name: 'reference',
};

export const TRANSACTION_FRAUD_REPORT_DETAILS = {
  name: 'details',
};

export const TRANSACTION_FRAUD_REPORT_COMMENT = {
  name: 'comment',
};

export const RETRIEVAL_REFERENCE_NUMBER = {
  label: 'RRN',
  name: 'retrieval_reference_number',
  copyable: true,
  cropText: true,
};

export const CARD_DATA_AUTH_CODE = {
  label: 'Auth',
  name: 'authorization_code',
  copyable: true,
  cropText: true,
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name]
      ? transaction[CARD_DATA_FIELD.name][name]
      : '',
};

export const CARD_DATA_MESSAGE_TYPE = {
  label: 'Message',
  name: 'message_type',
  sortName: 'MESSAGE_TYPE',
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name]
      ? MESSAGE_TYPE_MAP[transaction[CARD_DATA_FIELD.name][name]]
      : '',
};

const TRANSACTION_TYPE_FIELD_NAME = 'type';

export const TRANSACTION_STATUS = {
  label: 'Status',
  name: 'status',
  sortName: 'TRANSACTION_STATUS',
  renderAs: CUSTOM_FIELD_TYPES.STATUS_BADGE,
  getValue: (transaction, name) => {
    const bankDataField = transaction[BANK_DATA_FIELD.name];

    return bankDataField &&
      bankDataField[name] &&
      BANK_TRANSACTION_STATUS_MAP[bankDataField[name]]
      ? BANK_TRANSACTION_STATUS_MAP[bankDataField[name]]
      : TRANSACTION_STATUS_MAP[transaction[name]];
  },
};

const creditTransferStyles = (theme) => css`
  height: 18px;
  margin: 0 0 -${theme.spacings.bit};
`;

const InboundIcon = styled(ArrowRight)(
  ({ theme }) => css`
    ${creditTransferStyles(theme)}

    color: ${theme.colors.confirm};
    transform: rotate(135deg);
  `
);

const OutboundIcon = styled(ArrowRight)(
  ({ theme }) => css`
    ${creditTransferStyles(theme)}

    color: ${theme.colors.alert};
    transform: rotate(-45deg);
  `
);

const BalanceToBalanceIcon = styled(ArrowRight)(
  ({ theme }) => css`
    ${creditTransferStyles(theme)}

    color: ${theme.colors.v500};
  `
);

export const TOTAL_AMOUNT = {
  label: 'Amount',
  name: 'total_amount',
  sortName: 'TOTAL_AMOUNT',
  renderAs: CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT,
};

export const isInboundTransaction = (transaction) => {
  const transactionType = transaction[TRANSACTION_TYPE_FIELD_NAME];
  const bankDataField = transaction[BANK_DATA_FIELD.name];
  const amountField = transaction[TOTAL_AMOUNT.name];

  return (
    bankDataField &&
    bankDataField[TRANSACTION_TYPE_FIELD_NAME] &&
    !BANK_TRANSACTION_TYPE_MAP[bankDataField[TRANSACTION_TYPE_FIELD_NAME]] &&
    transactionType === TRANSACTION_TYPES.CREDIT_TRANSFER &&
    amountField?.amount > 0
  );
};

export const TRANSACTION_TYPE = {
  label: 'Type',
  name: TRANSACTION_TYPE_FIELD_NAME,
  sortName: 'TRANSACTION_TYPE',
  renderAs: CUSTOM_FIELD_TYPES.TEXT,
  getPrefix: (transaction, name) => {
    const transactionType = transaction[name];
    const amountField = transaction[TOTAL_AMOUNT.name];

    if (isSelfTransferReturn(transaction)) {
      return null;
    }

    if (transactionType === TRANSACTION_TYPES.CASH_DEPOSIT) {
      return amountField?.amount > 0 ? <InboundIcon /> : <OutboundIcon />;
    }

    if (isBalanceToBalance(transaction)) {
      return <BalanceToBalanceIcon />;
    }

    if (isInboundTransaction(transaction)) {
      return <InboundIcon />;
    }

    return <OutboundIcon />;
  },
  getValue: (transaction, name) => {
    const transactionType = transaction[name];
    const bankDataField = transaction[BANK_DATA_FIELD.name];
    const amountField = transaction[TOTAL_AMOUNT.name];

    if (isSelfTransferReturn(transaction)) {
      return SELF_TRANSFER;
    }

    if (bankDataField && bankDataField[name]) {
      if (BANK_TRANSACTION_TYPE_MAP[bankDataField[name]]) {
        return (BANK_TRANSACTION_TYPE_MAP[bankDataField[name]] || {}).label;
      }

      if (transactionType === TRANSACTION_TYPES.CREDIT_TRANSFER) {
        return amountField?.amount > 0
          ? INBOUND_BANK_TRANSFER_LABEL
          : OUTBOUND_BANK_TRANSFER_LABEL;
      }
    }

    return (TRANSACTION_TYPE_MAP[transactionType] || {}).label;
  },
};

export const CARD_DATA_POS_ENTRY_MODE = {
  label: 'Entry',
  name: 'pos_entry_mode',
  sortName: 'POS_ENTRY_MODE',
  renderAs: CUSTOM_FIELD_TYPES.ENTRY_MODE,
  getValue: (transaction, name) =>
    transaction[CARD_DATA_FIELD.name]
      ? transaction[CARD_DATA_FIELD.name][name]
      : '',
};

export const FEE_AMOUNT = {
  label: 'Fee',
  name: 'fee_amount',
  sortName: 'FEE_AMOUNT',
  renderAs: CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT,
  getValue: (transaction, name) =>
    (transaction[name] || {}).amount ? transaction[name] : null,
};

export const CARD_DATA_CLEARING_DATE = {
  label: 'Date',
  name: 'clearing_date',
  sortName: 'CLEARING_DATE',
  type: FIELD_TYPES.DATE_TIME,
  renderAs: FIELD_TYPES.DATE_TIME,
  getValue: getCardDataFieldValue,
  formatDate: (date) =>
    moment(date).format(DATE_FORMATS.SHORT_MONTH_DAY_YEAR_TIME),
};

export const CARD_DATA_CLEARING_AMOUNT = {
  label: 'Amount',
  name: 'clearing_total_amount',
  sortName: 'CLEARING_TOTAL_AMOUNT',
  renderAs: CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT,
  getValue: getCardDataFieldValue,
};

export const BILLING_AMOUNT = {
  label: 'Billed',
  name: 'billing_amount',
  sortName: 'BILLING_AMOUNT',
  renderAs: CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT,
  getValue: (transaction, name) => transaction[name],
};

export const CARD_DATA_CLEARING_BILLING_AMOUNT = {
  label: 'Cleared',
  name: 'clearing_billing_amount',
  sortName: 'CLEARING_BILLING_AMOUNT',
  renderAs: CUSTOM_FIELD_TYPES.COMPOSITE_AMOUNT,
  getValue: getCardDataFieldValue,
};

export const CARD_DATA_MCC = {
  label: 'MCC',
  name: 'merchant_category_code',
  sortName: 'MERCHANT_CATEGORY_CODE',
  renderAs: CUSTOM_FIELD_TYPES.MCC,
  getValue: getCardDataFieldValue,
};

export const CARD_DATA_MCC_DESCRIPTION = {
  name: 'merchant_category_code_description',
};

export const MERCHANT_DETAILS = {
  label: 'Details',
  name: 'details',
  sortName: 'DETAILS',
};

export const TRANSACTION_ORIGIN = {
  name: 'origin',
};

export const CARD_DATA_RESPONSE = {
  name: 'response',
};

export const CARD_DATA_WALLET_IDENTIFIER = {
  name: 'wallet_identifier',
};

export const CARD_DATA_WALLET_NAME = {
  name: 'wallet_name',
};

export const CARD_DATA_WALLET_DEVICE_PAN = {
  name: 'wallet_device_pan',
};

export const CARD_DATA_CARD_TOKEN = {
  name: 'card_token',
};

const BANK_DATA_DIRECT_DEBIT_DATA = {
  name: 'direct_debit_data',
};

export const BANK_DATA_MANDATE_ID = {
  label: 'Mandate',
  // name: 'mandate_id', // To do
  name: 'mandate_info',
  copyable: true,
  cropText: true,
  getValue: (transaction, name) =>
    transaction[BANK_DATA_FIELD.name] &&
    transaction[BANK_DATA_FIELD.name][BANK_DATA_DIRECT_DEBIT_DATA.name]
      ? transaction[BANK_DATA_FIELD.name][BANK_DATA_DIRECT_DEBIT_DATA.name][
          name
        ]
      : '',
};

export const TRANSACTION_CANCELLATION = {
  name: CUSTOM_FIELD_NAMES.TRANSACTION_CANCELLATION,
  isDisabled: (transaction) =>
    !transaction[CARD_DATA_FIELD.name] ||
    transaction[CARD_DATA_FIELD.name][TRANSACTION_CAN_CANCEL.name] === false,
  title: (
    <StyledPopoverOptionTitle color="alert">
      <Alert size="16" />
      Cancel transaction
    </StyledPopoverOptionTitle>
  ),
  confirmationModalInfo: {
    width: CANCEL_TRANSACTION_CONFIRMATION_MODAL_WIDTH,
    title: 'Cancel this transaction?',
    confirmText: 'Cancel transaction',
    cancelText: 'Never mind',
    confirmButtonProps: {
      destructive: true,
    },
    content: (
      <div>
        <Body noMargin size="one">
          This action is irreversible.
        </Body>
      </div>
    ),
    onConfirm: ({ identifier: txId }, { onCancelTransaction }) =>
      onCancelTransaction({
        txId,
      }),
  },
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsCancel,
  },
};

export const TRANSACTION_CHARGEBACK = {
  name: CUSTOM_FIELD_NAMES.TRANSACTION_CHARGEBACK,
  isDisabled: (transaction) =>
    !transaction[CARD_DATA_FIELD.name] ||
    transaction[CARD_DATA_FIELD.name][TRANSACTION_CAN_CHARGEBACK.name] ===
      false,
  title: (
    <StyledPopoverOptionTitle color="alert">
      <Refresh />
      Chargeback transaction
    </StyledPopoverOptionTitle>
  ),
  confirmationModalInfo: {
    width: CANCEL_TRANSACTION_CONFIRMATION_MODAL_WIDTH,
    title: 'Issue a chargeback for this transaction?',
    confirmText: 'Issue chargeback',
    confirmButtonProps: {
      destructive: true,
    },
    content: (
      <div>
        <Body noMargin size="one">
          This action is irreversible.
        </Body>
      </div>
    ),
    onConfirm: ({ identifier: txId }, { onChargebackTransaction }) =>
      onChargebackTransaction({
        txId,
      }),
  },
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsChargeback,
  },
};

export const TRANSACTION_MARK_AS_FRAUD = {
  name: '',
  isDisabled: (transaction) =>
    !transaction[CARD_DATA_FIELD.name] ||
    transaction[CARD_DATA_FIELD.name][TRANSACTION_CAN_MARK_AS_FRAUD.name] ===
      false,
  title: (
    <StyledPopoverOptionTitle color="alert">
      <PotentialFraud />
      Mark as potential fraud
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsFraud,
  },
};

export const MARK_FRAUD_REPORT = {
  name: '',
  title: (
    <StyledPopoverOptionTitle color="alert">
      <PotentialFraud />
      Mark as fraud
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsFraud,
  },
};

export const EDIT_FRAUD_REPORT = {
  name: '',
  title: (
    <StyledPopoverOptionTitle>
      <EditFraud />
      Edit fraud report
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsFraud,
  },
};

export const UNMARK_FRAUD_REPORT = {
  name: '',
  title: (
    <StyledPopoverOptionTitle color="alert">
      <UnmarkFraud />
      Unmark fraud
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsFraud,
  },
};

export const REQUEST_OPS_TRANSFER = {
  name: '',
  title: (
    <StyledPopoverOptionTitle>
      <RequestTransfer />
      Request a transfer
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsRequestTransfer,
  },
};

export const ISSUE_A_RETURN = {
  key: ISSUE_A_RETURN_ACTION,
  name: '',
  title: (
    <StyledPopoverOptionTitle color="alert">
      <Return />
      Issue a return
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    action: ACTIONS[APPS.merchant].accountcardTransactionsReturnSelfTransfer,
  },
};

export const DPAN = {
  name: CARD_DATA_WALLET_DEVICE_PAN.name,
  renderAs: CUSTOM_FIELD_TYPES.STATUS,
  getValue: getCardDataDpan,
};

export const DIRECTION = {
  name: 'direction',
};

export const DOWNLOAD_STATEMENT = {
  name: CUSTOM_FIELD_NAMES.TRANSACTION_STATEMENT,
  title: (
    <StyledPopoverOptionTitle>
      <Statement />
      Download statement
    </StyledPopoverOptionTitle>
  ),
  permissions: {
    app: APPS.merchant,
    section: SECTIONS[APPS.merchant].accountcardTransactions,
  },
};

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, capitalize } from 'lodash/fp';
import moment from 'moment';
import {
  Body,
  Button,
  Popover as CircuitPopover,
  Anchor,
  Input,
  Spinner,
  useModal,
  spacing,
  Badge,
} from '@sumup/circuit-ui';
import {
  MODALS,
  CARD_STATUSES_MAPPED,
  MFA_STATUSES,
  ACTIONS,
  APPS,
  DATE_FORMATS,
  CARD_STATUSES,
  CARD_TYPES,
  MULTIPLE_CARDS_TYPES,
} from 'variables';
import { ChevronUp, ChevronDown, SecurePayments, Truck } from '@sumup/icons';
import Modal from 'components/Modal';
import Status from 'components/Status';
import Empty from 'components/Empty';
import formatExpiryDate from 'services/formatExpiryDate';
import keyMirror from 'key-mirror-nested';
import { getPrimaryCard, getReplacementCard } from 'services/clientData';
import PsdStatus from '../PsdStatus';
import {
  StyledHeading,
  Block50,
  DividedBlock,
  StyledLabel,
  StyledText,
  StyledCard,
  CardHeader,
  CardFooter,
  Split,
  DividedBlockColumn,
  CardStatusPopoverComponent,
  Inactive,
  Row,
  GrayedOutSplit,
  ColoredText,
  StyledStatus,
  LockCardAction,
  LockedForAuditBanner,
  SpinnerWrapper,
  DividedBlockRow,
  CardHeadingBlock,
  StyledNotificationInline,
  ExpiringSoonBanner,
  AutoReplacedBanner,
  ViewShippingInfoAction,
  CircuitPopoverStyled,
} from '../../AccountStyled';
import virtualCard from '../../assets/virtual.png';
import physicalCard from '../../assets/physical.png';
import IssueCardModal from './components/IssueCardModal';
import LockCardConfirmationModal from '../LockCardConfirmationModal';
import { IssueCardTooltip } from './components/IssueCardModal/IssueCardTooltip';
import { CardContent } from './ActiveCardsStyled';
import { getStatusPopoverOptions } from '../../statusPopoverOptions';
import ShippingInformationModal from '../ShippingInformationModal';

const STATUSES = {
  [MFA_STATUSES.ENROLLED]: MODALS.PSD_STATUS_UNENROLL,
  [MFA_STATUSES.UNENROLLED]: MODALS.PSD_STATUS_ENROLL,
};
const STATUSES_BLOCKING = {
  [MFA_STATUSES.ENROLLED]: MODALS.PSD_STATUS_BLOCK,
  [MFA_STATUSES.BLOCKED]: MODALS.PSD_STATUS_UNBLOCK,
};
const STATUSES_TO_SET = {
  enrolled: 'unenrolled',
  unenrolled: 'enrolled',
};
const STATUSES_TO_SET_BLOCKING = {
  blocked: 'enrolled',
  enrolled: 'blocked',
};

const CARD_STATUS_OPTIONS = [
  CARD_STATUSES.ACTIVE,
  CARD_STATUSES.ACTIVE_NO_RENEWAL,
  CARD_STATUSES.BLOCKED_TEMPORARILY,
  CARD_STATUSES.LOST,
  CARD_STATUSES.STOLEN,
  CARD_STATUSES.BLOCKED_PERMANENTLY,
];

const {
  accountcardOverview3ds,
  accountcardOverviewIssueCard,
  accountcardOverviewPin,
  accountcardOverviewStatus,
  accountcardOverviewAudit,
  accountcardOverviewCardShipment,
  accountcardOverviewReplaceCard,
  accountcardOverviewEditWallet,
} = ACTIONS[APPS.merchant];

export const SELECTORS = keyMirror({
  AUTO_REPLACED: null,
  EXPIRING_SOON: null,
  REPLACE_CARD: null,
  PRIMARY_CARD_BADGE: null,
});

const ActiveCards = ({
  activeCards,
  onCardStatusChange,
  onIssueCard,
  onClearPinAttempts,
  clientId,
  onPsdStatusChange,
  isLoading,
  primaryPsdStatusLoading,
  replacementPsdStatusLoading,
  address,
  personalProfile,
  paymentsBlocked,
  onLockCardForAudit,
  lockCardForAuditLoading,
  primaryCardStatusLoading,
  replacementCardStatusLoading,
  issueCardFail,
  replaceCardFail,
  hasActionAccess,
  onWalletTokensModalOpen,
  onReplaceModalOpen,
  replaceCardLoading,
}) => {
  const [primaryCardStatusPopoverOpen, setPrimaryCardStatusPopoverOpen] =
    useState(false);
  const [
    replacementCardStatusPopoverOpen,
    setReplacementCardStatusPopoverOpen,
  ] = useState(false);
  const [primaryMorePopoverOpen, setPrimaryMorePopoverOpen] = useState(false);
  const [replacementMorePopoverOpen, setReplacementMorePopoverOpen] =
    useState(false);
  const comment = useRef('');
  const multipleCards = activeCards.length > 1;

  const { setModal } = useModal();

  const noPrimaryCard = isEmpty(getPrimaryCard(activeCards));

  const primaryCard =
    activeCards &&
    ((activeCards.length === 1 &&
      activeCards[0].is_primary === false &&
      activeCards[0]) ||
      (multipleCards && noPrimaryCard && activeCards[1]) ||
      getPrimaryCard(activeCards));

  const {
    id: primaryCardId,
    status: primaryCardCardStatus,
    cardholder_name: primaryCardCardholderName,
    masked_pan: primaryCardMaskedPan,
    expiry_date: primaryCardExpiryDate,
    token: primaryCardToken,
    source: primaryCardSource,
    client_pin_set: primaryCardPinSet,
    activation_date: primaryCardActivationDate,
    issue_date: primaryCardIssueDate,
    card_type: primaryCardCardType,
    expiring_soon: primaryCardExpiringSoon,
    automatically_replaced: primaryCardAutomaticallyReplaced,
  } = primaryCard || {};

  let replacementCard = {};

  if (multipleCards) {
    replacementCard = noPrimaryCard
      ? activeCards[0]
      : getReplacementCard(activeCards);
  }

  const primaryCardVirtual = primaryCardCardType === CARD_TYPES.virtual;

  const formattedCardExpiryDate = primaryCardExpiryDate
    ? formatExpiryDate(primaryCardExpiryDate)
    : '';

  const issueNewCardDisabled = primaryCard && !isEmpty(primaryCard);

  const canLockForAudit = hasActionAccess({ action: accountcardOverviewAudit });
  const canAccessShippingInformation = hasActionAccess({
    action: accountcardOverviewCardShipment,
  });
  const showMoreOptions = canLockForAudit || canAccessShippingInformation;

  const canReplaceCard = () =>
    hasActionAccess({
      action: accountcardOverviewReplaceCard,
    });

  const canManageWalletTokens = hasActionAccess({
    action: accountcardOverviewEditWallet,
  });

  const handleCommentChange = ({ target: { value } }) => {
    comment.current = value;
  };

  const renderCardStatusModalContent = ({ status }) => (
    <>
      <Body noMargin size="two">
        The status of{' '}
        <Body noMargin size="two" variant="highlight">
          card {primaryCardId}
        </Body>{' '}
        will be changed to:
      </Body>
      <StyledStatus value={CARD_STATUSES_MAPPED[status]} />
      <Input
        placeholder="Comment (optional)"
        label="Comment"
        hideLabel
        onChange={handleCommentChange}
        noMargin
      />
    </>
  );

  const handleCardStatusChange = ({ token, status, type }) => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            ...MODALS.CHANGE_CARD_STATUS,
            onConfirm: () =>
              onCardStatusChange({
                token,
                status,
                comment: comment.current,
                type,
              }),
            content: renderCardStatusModalContent({ status }),
          }}
          onClose={onClose}
        />
      ),
      closeButtonLabel: 'Close',
      onClose: () => {},
    });
  };

  const handlePsdStatusChange = (
    { psd_status: psdStatus, token },
    type,
    block
  ) => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <Modal
          modalInfo={{
            ...(block ? STATUSES_BLOCKING[psdStatus] : STATUSES[psdStatus]),
            isLoading: primaryPsdStatusLoading || replacementPsdStatusLoading,
            onConfirm: () =>
              onPsdStatusChange({
                status: block
                  ? STATUSES_TO_SET_BLOCKING[psdStatus]
                  : STATUSES_TO_SET[psdStatus],
                token,
                type,
              }),
          }}
          onClose={onClose}
        />
      ),
      closeButtonLabel: 'Close',
      onClose: () => {},
    });
  };

  const cardStatusPopoverActions = (token, type, cardStatus) =>
    hasActionAccess({
      action: accountcardOverviewPin,
    })
      ? getStatusPopoverOptions({
          statuses: CARD_STATUS_OPTIONS,
          statusesMap: CARD_STATUSES_MAPPED,
          onClick: ({ status }) =>
            handleCardStatusChange({ token, status, type }),
          isOptionDisabled: (status) => status === cardStatus,
        })
      : [];

  const handleCardStatusPopoverComponentClick = (e, type) => {
    if (e?.stopPropagation) {
      e.stopPropagation();
    }

    if (type === MULTIPLE_CARDS_TYPES.primary) {
      setPrimaryCardStatusPopoverOpen(true);
    } else {
      setReplacementCardStatusPopoverOpen(true);
    }
  };

  const cardStatusPopoverComponent = (status, type) => {
    const disabled =
      !hasActionAccess({
        action: accountcardOverviewStatus,
      }) || paymentsBlocked;

    return (
      <CardStatusPopoverComponent
        disabled={disabled}
        onClick={(e) => handleCardStatusPopoverComponentClick(e, type)}
      >
        <Status value={CARD_STATUSES_MAPPED[status]} />
        {!disabled &&
          (primaryCardStatusPopoverOpen ? <ChevronUp /> : <ChevronDown />)}
      </CardStatusPopoverComponent>
    );
  };

  const handleIssueCard = () => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <IssueCardModal
          address={address}
          personalProfile={personalProfile}
          onClose={onClose}
          onIssueCard={onIssueCard}
        />
      ),
      closeButtonLabel: 'Close',
      onClose: () => {},
    });
  };

  const handleLockForAudit = ({ token }) => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: ({ onClose }) => (
        <LockCardConfirmationModal
          paymentsBlocked={paymentsBlocked}
          cardToken={token}
          onClose={onClose}
          onConfirm={onLockCardForAudit}
        />
      ),
      onClose: () => {},
      closeButtonLabel: 'Close',
    });
  };

  const handleViewShippingInfo = ({ token, cardId }) => {
    setModal({
      // eslint-disable-next-line react/prop-types
      children: () => (
        <ShippingInformationModal
          clientId={clientId}
          cardToken={token}
          cardId={cardId}
        />
      ),
      closeButtonLabel: 'Close',
    });
  };

  const morePopoverComponent = (type) => (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        if (type === MULTIPLE_CARDS_TYPES.primary) {
          setPrimaryMorePopoverOpen(true);
        } else {
          setReplacementMorePopoverOpen(true);
        }
      }}
      css={spacing({ left: 'mega' })}
    >
      More options
    </Button>
  );

  const morePopoverActions = (token, type, cardId) => [
    {
      ...(canAccessShippingInformation && {
        children: (
          <ViewShippingInfoAction>
            <Truck />
            View shipping information
          </ViewShippingInfoAction>
        ),
        onClick: () => handleViewShippingInfo({ token, cardId }),
      }),
    },
    {
      ...(canLockForAudit && {
        children: (
          <LockCardAction>
            <SecurePayments />
            {paymentsBlocked ? 'Unlock' : 'Lock'} this card for audit
          </LockCardAction>
        ),
        onClick: () => handleLockForAudit({ token, type }),
      }),
    },
  ];

  return (
    <Row>
      <Split>
        <StyledHeading as="h3" size="one" noMargin>
          Active cards
        </StyledHeading>
        {hasActionAccess({
          action: accountcardOverviewIssueCard,
        }) && (
          <IssueCardTooltip
            disabled={issueNewCardDisabled}
            isLoading={isLoading}
            cardId={primaryCardId}
            onIssueCard={handleIssueCard}
          />
        )}
      </Split>
      {(issueCardFail || replaceCardFail) && (
        <StyledNotificationInline
          variant="alert"
          css={spacing({ bottom: 'mega' })}
          body={
            (issueCardFail && 'Issuing card failed.') ||
            (replaceCardFail && 'Replacing card failed.')
          }
        />
      )}
      {(activeCards.length === 1 && (
        <Block50>
          <StyledCard>
            <CardHeader type={primaryCardCardType}>
              <img
                src={primaryCardVirtual ? virtualCard : physicalCard}
                alt="Card"
              />
              <CardContent>
                <CardHeadingBlock>
                  <Body variant="highlight" size="one" noMargin>
                    {primaryCardCardholderName}
                  </Body>
                  <DividedBlockRow>
                    <DividedBlockColumn>
                      <StyledLabel noMargin size="two">
                        TYPE:
                      </StyledLabel>
                      <StyledText noMargin cardType={primaryCardCardType}>
                        {capitalize(primaryCardCardType)}
                      </StyledText>
                    </DividedBlockColumn>
                    <DividedBlockColumn>
                      <StyledLabel noMargin size="two">
                        ISSUED ON:
                      </StyledLabel>
                      <StyledText noMargin>
                        {primaryCardIssueDate ? (
                          moment(primaryCardIssueDate).format(
                            DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                          )
                        ) : (
                          <Empty>—</Empty>
                        )}
                      </StyledText>
                    </DividedBlockColumn>
                    <DividedBlockColumn>
                      <StyledLabel noMargin size="two">
                        PAN:
                      </StyledLabel>
                      <StyledText noMargin>
                        {primaryCardMaskedPan || <Empty>—</Empty>}
                      </StyledText>
                    </DividedBlockColumn>
                    <DividedBlockColumn>
                      <StyledLabel noMargin size="two">
                        EXP. DATE:
                      </StyledLabel>
                      <StyledText noMargin>
                        {primaryCardExpiryDate ? (
                          formattedCardExpiryDate
                        ) : (
                          <Empty>—</Empty>
                        )}
                      </StyledText>
                    </DividedBlockColumn>
                    {!primaryCardVirtual && (
                      <DividedBlockColumn>
                        <StyledLabel noMargin size="two">
                          ACTIVATED ON:
                        </StyledLabel>
                        <StyledText noMargin>
                          {primaryCardActivationDate ? (
                            moment(primaryCardActivationDate).format(
                              DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                            )
                          ) : (
                            <Empty>—</Empty>
                          )}
                        </StyledText>
                      </DividedBlockColumn>
                    )}
                  </DividedBlockRow>
                </CardHeadingBlock>
                <SpinnerWrapper>
                  {(primaryPsdStatusLoading ||
                    lockCardForAuditLoading ||
                    primaryCardStatusLoading) && <Spinner />}
                </SpinnerWrapper>
              </CardContent>
            </CardHeader>
            {paymentsBlocked && (
              <LockedForAuditBanner>
                <Body size="two" noMargin>
                  This card is&nbsp;
                </Body>
                <Body size="two" variant="highlight" noMargin>
                  locked for audit
                </Body>
                <Body size="two" noMargin>
                  . No transactions are permitted.
                </Body>
              </LockedForAuditBanner>
            )}
            {primaryCardAutomaticallyReplaced && (
              <AutoReplacedBanner data-testid={SELECTORS.AUTO_REPLACED}>
                <Body size="two" noMargin>
                  The merchant&lsquo;s card &nbsp;
                </Body>
                <Body size="two" variant="highlight" noMargin>
                  recently expired &nbsp;
                </Body>
                <Body size="two" noMargin>
                  and was replaced automatically with a new one.
                </Body>
              </AutoReplacedBanner>
            )}
            {primaryCardExpiringSoon && (
              <ExpiringSoonBanner data-testid={SELECTORS.EXPIRING_SOON}>
                <Body size="two" noMargin>
                  This card &nbsp;
                </Body>
                <Body size="two" variant="highlight" noMargin>
                  expires soon.
                </Body>
              </ExpiringSoonBanner>
            )}
            <DividedBlock spread>
              <DividedBlockColumn>
                <StyledLabel noMargin size="two">
                  ID:
                </StyledLabel>
                <StyledText noMargin>{primaryCardId}</StyledText>
              </DividedBlockColumn>
              <DividedBlockColumn>
                <StyledLabel noMargin size="two">
                  TOKEN:
                </StyledLabel>
                <StyledText noMargin>{primaryCardToken}</StyledText>
              </DividedBlockColumn>
              <DividedBlockColumn>
                <StyledLabel noMargin size="two">
                  SOURCE:
                </StyledLabel>
                <StyledText noMargin>{primaryCardSource}</StyledText>
              </DividedBlockColumn>
            </DividedBlock>
            <DividedBlock spread>
              <DividedBlockColumn>
                <StyledLabel noMargin size="two">
                  STATUS:
                </StyledLabel>
                <CircuitPopover
                  isOpen={primaryCardStatusPopoverOpen}
                  placement="bottom"
                  actions={cardStatusPopoverActions(
                    primaryCardToken,
                    MULTIPLE_CARDS_TYPES.primary,
                    primaryCardCardStatus
                  )}
                  component={() =>
                    cardStatusPopoverComponent(
                      primaryCardCardStatus,
                      MULTIPLE_CARDS_TYPES.primary
                    )
                  }
                  onToggle={() =>
                    setPrimaryCardStatusPopoverOpen(
                      !primaryCardStatusPopoverOpen
                    )
                  }
                />
              </DividedBlockColumn>
              <DividedBlockColumn>
                <StyledLabel noMargin size="two">
                  3D SECURE:
                </StyledLabel>
                <PsdStatus
                  disabled={
                    paymentsBlocked ||
                    !hasActionAccess({ action: accountcardOverview3ds })
                  }
                  card={primaryCard}
                  onPsdStatusChange={handlePsdStatusChange}
                  type={MULTIPLE_CARDS_TYPES.primary}
                />
              </DividedBlockColumn>
              <DividedBlockColumn>
                <StyledLabel noMargin size="two">
                  CARD PIN:
                </StyledLabel>
                <GrayedOutSplit
                  disabled={
                    !hasActionAccess({ action: accountcardOverviewPin })
                  }
                >
                  <ColoredText active={primaryCardPinSet} size="two" noMargin>
                    {primaryCardPinSet ? 'Set' : 'Not set yet'}
                  </ColoredText>
                  {hasActionAccess({ action: accountcardOverviewPin }) &&
                    primaryCardPinSet && (
                      <Anchor
                        size="two"
                        onClick={() =>
                          hasActionAccess({
                            action: accountcardOverviewPin,
                          }) &&
                          onClearPinAttempts({ cardToken: primaryCardToken })
                        }
                      >
                        Clear attempts
                      </Anchor>
                    )}
                </GrayedOutSplit>
              </DividedBlockColumn>
            </DividedBlock>
            <CardFooter>
              {canManageWalletTokens && (
                <Button
                  onClick={() =>
                    onWalletTokensModalOpen({
                      cardId: primaryCardId,
                      cardToken: primaryCardToken,
                    })
                  }
                >
                  Manage wallet tokens
                </Button>
              )}
              {canReplaceCard() && (
                <Button
                  onClick={onReplaceModalOpen}
                  css={spacing({ left: 'mega' })}
                  data-testid={SELECTORS.REPLACE_CARD}
                  disabled={replaceCardLoading || paymentsBlocked}
                  isLoading={replaceCardLoading}
                  loadingLabel="Loading"
                >
                  Replace card
                </Button>
              )}
              {showMoreOptions && (
                <CircuitPopoverStyled
                  isOpen={primaryMorePopoverOpen}
                  align="end"
                  placement="bottom"
                  actions={morePopoverActions(
                    primaryCardToken,
                    MULTIPLE_CARDS_TYPES.primary,
                    primaryCardId
                  )}
                  component={() =>
                    morePopoverComponent(MULTIPLE_CARDS_TYPES.primary)
                  }
                  onToggle={() =>
                    setPrimaryMorePopoverOpen(!primaryMorePopoverOpen)
                  }
                />
              )}
            </CardFooter>
          </StyledCard>
        </Block50>
      )) ||
        (multipleCards && (
          <>
            <Block50>
              <StyledCard>
                <CardHeader type={primaryCardCardType}>
                  <img
                    src={
                      primaryCardCardType === CARD_TYPES.virtual
                        ? virtualCard
                        : physicalCard
                    }
                    alt="Card"
                  />
                  <CardContent>
                    <CardHeadingBlock
                      {...(!noPrimaryCard && { primary: true })}
                    >
                      <Body variant="highlight" size="one" noMargin>
                        {primaryCardCardholderName}
                      </Body>
                      {!noPrimaryCard && (
                        <Badge
                          variant="promo"
                          size="one"
                          noMargin
                          css={spacing({ left: 'mega' })}
                          data-testid={SELECTORS.PRIMARY_CARD_BADGE}
                        >
                          Primary card
                        </Badge>
                      )}
                      <DividedBlockRow
                        {...(!noPrimaryCard && { primary: true })}
                      >
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            TYPE:
                          </StyledLabel>
                          <StyledText noMargin cardType={primaryCardCardType}>
                            {capitalize(primaryCardCardType)}
                          </StyledText>
                        </DividedBlockColumn>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            ISSUED ON:
                          </StyledLabel>
                          <StyledText noMargin>
                            {primaryCardIssueDate ? (
                              moment(primaryCardIssueDate).format(
                                DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                              )
                            ) : (
                              <Empty>—</Empty>
                            )}
                          </StyledText>
                        </DividedBlockColumn>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            PAN:
                          </StyledLabel>
                          <StyledText noMargin>
                            {primaryCardMaskedPan || <Empty>—</Empty>}
                          </StyledText>
                        </DividedBlockColumn>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            EXP. DATE:
                          </StyledLabel>
                          <StyledText noMargin>
                            {primaryCardExpiryDate ? (
                              formatExpiryDate(primaryCardExpiryDate)
                            ) : (
                              <Empty>—</Empty>
                            )}
                          </StyledText>
                        </DividedBlockColumn>
                        {primaryCardCardType === CARD_TYPES.physical && (
                          <DividedBlockColumn>
                            <StyledLabel noMargin size="two">
                              ACTIVATED ON:
                            </StyledLabel>
                            <StyledText noMargin>
                              {primaryCardActivationDate ? (
                                moment(primaryCardActivationDate).format(
                                  DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                                )
                              ) : (
                                <Empty>—</Empty>
                              )}
                            </StyledText>
                          </DividedBlockColumn>
                        )}
                      </DividedBlockRow>
                    </CardHeadingBlock>
                    <SpinnerWrapper>
                      {(primaryPsdStatusLoading ||
                        lockCardForAuditLoading ||
                        primaryCardStatusLoading) && <Spinner />}
                    </SpinnerWrapper>
                  </CardContent>
                </CardHeader>
                {paymentsBlocked && (
                  <LockedForAuditBanner>
                    <Body size="two" noMargin>
                      This card is&nbsp;
                    </Body>
                    <Body size="two" variant="highlight" noMargin>
                      locked for audit
                    </Body>
                    <Body size="two" noMargin>
                      . No transactions are permitted.
                    </Body>
                  </LockedForAuditBanner>
                )}
                {primaryCardAutomaticallyReplaced && (
                  <AutoReplacedBanner data-testid={SELECTORS.AUTO_REPLACED}>
                    <Body size="two" noMargin>
                      The merchant&lsquo;s card &nbsp;
                    </Body>
                    <Body size="two" variant="highlight" noMargin>
                      recently expired &nbsp;
                    </Body>
                    <Body size="two" noMargin>
                      and was replaced automatically with a new one.
                    </Body>
                  </AutoReplacedBanner>
                )}
                {primaryCardExpiringSoon && (
                  <ExpiringSoonBanner data-testid={SELECTORS.EXPIRING_SOON}>
                    <Body size="two" noMargin>
                      This card &nbsp;
                    </Body>
                    <Body size="two" variant="highlight" noMargin>
                      expires soon.
                    </Body>
                  </ExpiringSoonBanner>
                )}
                <DividedBlock spread>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      ID:
                    </StyledLabel>
                    <StyledText noMargin>{primaryCardId}</StyledText>
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      TOKEN:
                    </StyledLabel>
                    <StyledText noMargin>{primaryCardToken}</StyledText>
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      SOURCE:
                    </StyledLabel>
                    <StyledText noMargin>{primaryCardSource}</StyledText>
                  </DividedBlockColumn>
                </DividedBlock>
                <DividedBlock spread>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      STATUS:
                    </StyledLabel>
                    <CircuitPopover
                      isOpen={primaryCardStatusPopoverOpen}
                      placement="bottom"
                      actions={cardStatusPopoverActions(
                        primaryCardToken,
                        MULTIPLE_CARDS_TYPES.primary,
                        primaryCardCardStatus
                      )}
                      component={() =>
                        cardStatusPopoverComponent(
                          primaryCardCardStatus,
                          MULTIPLE_CARDS_TYPES.primary
                        )
                      }
                      onToggle={() =>
                        setPrimaryCardStatusPopoverOpen(
                          !primaryCardStatusPopoverOpen
                        )
                      }
                    />
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      3D SECURE:
                    </StyledLabel>
                    <PsdStatus
                      disabled={
                        paymentsBlocked ||
                        !hasActionAccess({ action: accountcardOverview3ds })
                      }
                      card={primaryCard}
                      onPsdStatusChange={handlePsdStatusChange}
                      type={MULTIPLE_CARDS_TYPES.primary}
                    />
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      CARD PIN:
                    </StyledLabel>
                    <GrayedOutSplit
                      disabled={
                        !hasActionAccess({ action: accountcardOverviewPin })
                      }
                    >
                      <ColoredText
                        active={primaryCardPinSet}
                        size="two"
                        noMargin
                      >
                        {primaryCardPinSet ? 'Set' : 'Not set yet'}
                      </ColoredText>
                      {hasActionAccess({ action: accountcardOverviewPin }) &&
                        primaryCardPinSet && (
                          <Anchor
                            size="two"
                            onClick={() =>
                              hasActionAccess({
                                action: accountcardOverviewPin,
                              }) &&
                              onClearPinAttempts({
                                cardToken: primaryCardToken,
                              })
                            }
                          >
                            Clear attempts
                          </Anchor>
                        )}
                    </GrayedOutSplit>
                  </DividedBlockColumn>
                </DividedBlock>
                <CardFooter>
                  {canManageWalletTokens && (
                    <Button
                      onClick={() =>
                        onWalletTokensModalOpen({
                          cardId: primaryCardId,
                          cardToken: primaryCardToken,
                        })
                      }
                    >
                      Manage wallet tokens
                    </Button>
                  )}
                  {canReplaceCard() && (
                    <Button
                      disabled
                      css={spacing({ left: 'mega' })}
                      data-testid={SELECTORS.REPLACE_CARD}
                    >
                      Replace card
                    </Button>
                  )}
                  {showMoreOptions && (
                    <CircuitPopoverStyled
                      isOpen={primaryMorePopoverOpen}
                      align="end"
                      placement="bottom"
                      actions={morePopoverActions(
                        primaryCardToken,
                        MULTIPLE_CARDS_TYPES.primary,
                        primaryCardId
                      )}
                      component={() =>
                        morePopoverComponent(MULTIPLE_CARDS_TYPES.primary)
                      }
                      onToggle={() =>
                        setPrimaryMorePopoverOpen(!primaryMorePopoverOpen)
                      }
                    />
                  )}
                </CardFooter>
              </StyledCard>
            </Block50>
            <Block50>
              <StyledCard>
                <CardHeader type={replacementCard.card_type}>
                  <img
                    src={
                      replacementCard.card_type === CARD_TYPES.virtual
                        ? virtualCard
                        : physicalCard
                    }
                    alt="Card"
                  />
                  <CardContent>
                    <CardHeadingBlock>
                      <Body variant="highlight" size="one" noMargin>
                        {replacementCard.cardholder_name}
                      </Body>
                      <DividedBlockRow>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            TYPE:
                          </StyledLabel>
                          <StyledText
                            noMargin
                            cardType={replacementCard.card_type}
                          >
                            {capitalize(replacementCard.card_type)}
                          </StyledText>
                        </DividedBlockColumn>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            ISSUED ON:
                          </StyledLabel>
                          <StyledText noMargin>
                            {replacementCard.issue_date ? (
                              moment(replacementCard.issue_date).format(
                                DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                              )
                            ) : (
                              <Empty>—</Empty>
                            )}
                          </StyledText>
                        </DividedBlockColumn>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            PAN:
                          </StyledLabel>
                          <StyledText noMargin>
                            {replacementCard.masked_pan || <Empty>—</Empty>}
                          </StyledText>
                        </DividedBlockColumn>
                        <DividedBlockColumn>
                          <StyledLabel noMargin size="two">
                            EXP. DATE:
                          </StyledLabel>
                          <StyledText noMargin>
                            {replacementCard.expiry_date ? (
                              formatExpiryDate(replacementCard.expiry_date)
                            ) : (
                              <Empty>—</Empty>
                            )}
                          </StyledText>
                        </DividedBlockColumn>
                        {replacementCard.card_type !== CARD_TYPES.virtual && (
                          <DividedBlockColumn>
                            <StyledLabel noMargin size="two">
                              ACTIVATED ON:
                            </StyledLabel>
                            <StyledText noMargin>
                              {replacementCard.activation_date ? (
                                moment(replacementCard.activation_date).format(
                                  DATE_FORMATS.SHORT_MONTH_DAY_YEAR
                                )
                              ) : (
                                <Empty>—</Empty>
                              )}
                            </StyledText>
                          </DividedBlockColumn>
                        )}
                      </DividedBlockRow>
                    </CardHeadingBlock>
                    <SpinnerWrapper>
                      {(replacementPsdStatusLoading ||
                        lockCardForAuditLoading ||
                        replacementCardStatusLoading) && <Spinner />}
                    </SpinnerWrapper>
                  </CardContent>
                </CardHeader>
                {paymentsBlocked && (
                  <LockedForAuditBanner>
                    <Body size="two" noMargin>
                      This card is&nbsp;
                    </Body>
                    <Body size="two" variant="highlight" noMargin>
                      locked for audit
                    </Body>
                    <Body size="two" noMargin>
                      . No transactions are permitted.
                    </Body>
                  </LockedForAuditBanner>
                )}
                {replacementCard.automatically_replaced && (
                  <AutoReplacedBanner data-testid={SELECTORS.AUTO_REPLACED}>
                    <Body size="two" noMargin>
                      The merchant&lsquo;s card &nbsp;
                    </Body>
                    <Body size="two" variant="highlight" noMargin>
                      recently expired &nbsp;
                    </Body>
                    <Body size="two" noMargin>
                      and was replaced automatically with a new one.
                    </Body>
                  </AutoReplacedBanner>
                )}
                {replacementCard.expiring_soon && (
                  <ExpiringSoonBanner data-testid={SELECTORS.EXPIRING_SOON}>
                    <Body size="two" noMargin>
                      This card &nbsp;
                    </Body>
                    <Body size="two" variant="highlight" noMargin>
                      expires soon.
                    </Body>
                  </ExpiringSoonBanner>
                )}
                <DividedBlock spread>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      ID:
                    </StyledLabel>
                    <StyledText noMargin>{replacementCard.id}</StyledText>
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      TOKEN:
                    </StyledLabel>
                    <StyledText noMargin>{replacementCard.token}</StyledText>
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      SOURCE:
                    </StyledLabel>
                    <StyledText noMargin>{replacementCard.source}</StyledText>
                  </DividedBlockColumn>
                </DividedBlock>
                <DividedBlock spread>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      STATUS:
                    </StyledLabel>
                    <CircuitPopover
                      isOpen={replacementCardStatusPopoverOpen}
                      placement="bottom"
                      actions={cardStatusPopoverActions(
                        replacementCard.token,
                        MULTIPLE_CARDS_TYPES.replacement,
                        replacementCard.status
                      )}
                      component={() =>
                        cardStatusPopoverComponent(
                          replacementCard.status,
                          MULTIPLE_CARDS_TYPES.replacement
                        )
                      }
                      onToggle={() =>
                        setReplacementCardStatusPopoverOpen(
                          !replacementCardStatusPopoverOpen
                        )
                      }
                    />
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      3D SECURE:
                    </StyledLabel>
                    <PsdStatus
                      disabled={
                        paymentsBlocked ||
                        !hasActionAccess({ action: accountcardOverview3ds })
                      }
                      card={replacementCard}
                      onPsdStatusChange={handlePsdStatusChange}
                      type={MULTIPLE_CARDS_TYPES.replacement}
                    />
                  </DividedBlockColumn>
                  <DividedBlockColumn>
                    <StyledLabel noMargin size="two">
                      CARD PIN:
                    </StyledLabel>
                    <GrayedOutSplit
                      disabled={
                        !hasActionAccess({ action: accountcardOverviewPin })
                      }
                    >
                      <ColoredText
                        active={replacementCard.client_pin_set}
                        size="two"
                        noMargin
                      >
                        {replacementCard.client_pin_set ? 'Set' : 'Not set yet'}
                      </ColoredText>
                      {hasActionAccess({ action: accountcardOverviewPin }) &&
                        replacementCard.client_pin_set && (
                          <Anchor
                            size="two"
                            onClick={() =>
                              hasActionAccess({
                                action: accountcardOverviewPin,
                              }) &&
                              onClearPinAttempts({
                                cardToken: primaryCardToken,
                              })
                            }
                          >
                            Clear attempts
                          </Anchor>
                        )}
                    </GrayedOutSplit>
                  </DividedBlockColumn>
                </DividedBlock>
                <CardFooter>
                  <Button
                    onClick={() =>
                      onWalletTokensModalOpen({
                        cardId: replacementCard.id,
                        cardToken: replacementCard.token,
                      })
                    }
                  >
                    Manage wallet tokens
                  </Button>
                  {showMoreOptions && (
                    <CircuitPopoverStyled
                      isOpen={replacementMorePopoverOpen}
                      align="end"
                      placement="bottom"
                      actions={morePopoverActions(
                        replacementCard.token,
                        MULTIPLE_CARDS_TYPES.replacement,
                        replacementCard.id
                      )}
                      component={() =>
                        morePopoverComponent(MULTIPLE_CARDS_TYPES.replacement)
                      }
                      onToggle={() =>
                        setReplacementMorePopoverOpen(
                          !replacementMorePopoverOpen
                        )
                      }
                    />
                  )}
                </CardFooter>
              </StyledCard>
            </Block50>
          </>
        )) || (
          <Inactive>This merchant does not have any active cards.</Inactive>
        )}
    </Row>
  );
};

ActiveCards.defaultProps = {
  isLoading: false,
  primaryPsdStatusLoading: false,
  replacementPsdStatusLoading: false,
  activeCards: [],
  paymentsBlocked: false,
  lockCardForAuditLoading: false,
  primaryCardStatusLoading: false,
  replacementCardStatusLoading: false,
  issueCardFail: false,
  replaceCardFail: false,
  replaceCardLoading: false,
};

ActiveCards.propTypes = {
  activeCards: PropTypes.array,
  onCardStatusChange: PropTypes.func.isRequired,
  onIssueCard: PropTypes.func.isRequired,
  onClearPinAttempts: PropTypes.func.isRequired,
  clientId: PropTypes.string.isRequired,
  onPsdStatusChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  primaryPsdStatusLoading: PropTypes.bool,
  replacementPsdStatusLoading: PropTypes.bool,
  address: PropTypes.object.isRequired,
  personalProfile: PropTypes.object.isRequired,
  paymentsBlocked: PropTypes.bool,
  onLockCardForAudit: PropTypes.func.isRequired,
  lockCardForAuditLoading: PropTypes.bool,
  primaryCardStatusLoading: PropTypes.bool,
  replacementCardStatusLoading: PropTypes.bool,
  issueCardFail: PropTypes.bool,
  replaceCardFail: PropTypes.bool,
  hasActionAccess: PropTypes.func.isRequired,
  onWalletTokensModalOpen: PropTypes.func.isRequired,
  onReplaceModalOpen: PropTypes.func.isRequired,
  replaceCardLoading: PropTypes.bool,
};

export default ActiveCards;

import keyMirror from 'key-mirror-nested';

export const APPS = keyMirror({
  merchant: null,
  administration: null,
  reports: null,
  mass_actions: null,
  card_reconciliation: null,
  ops_transfers: null,
});

export const SECTIONS = {
  [APPS.merchant]: keyMirror({
    accountcardActivation: null,
    accountcardActivityLog: null,
    accountcardLimits: null,
    accountcardOverview: null,
    accountcardPsdChallenges: null,
    accountcardScaDevices: null,
    accountcardTransactions: null,
    accountcardDirectDebits: null,
    accountcardScheduledPayments: null,
  }),
  [APPS.administration]: keyMirror({
    groups: null,
    users: null,
  }),
  [APPS.reports]: keyMirror({
    mcReport: null,
  }),
  [APPS.mass_actions]: keyMirror({
    actions: null,
  }),
  [APPS.card_reconciliation]: keyMirror({
    authorizations: null,
    clearings: null,
    cancelauthorizations: null,
  }),
  [APPS.ops_transfers]: keyMirror({
    requests: null,
  }),
};

export const ACTIONS = {
  [APPS.merchant]: keyMirror({
    accountcard: null,
    accountcardActivationActivate: null,
    accountcardLimitsEdit: null,
    accountcardOverview3ds: null,
    accountcardOverviewIssueAccount: null,
    accountcardOverviewIssueCard: null,
    accountcardOverviewPin: null,
    accountcardOverviewStatus: null,
    accountcardOverviewEditWallet: null,
    accountcardOverviewAudit: null,
    accountcardOverviewTransfer: null,
    accountcardOverviewRequestTransfer: null,
    accountcardOverviewReplaceCard: null,
    accountcardTransactionsCancel: null,
    accountcardTransactionsChargeback: null,
    accountcardTransactionsExport: null,
    accountcardTransactionsFraud: null,
    accountcardTransactionsRequestTransfer: null,
    accountcardTransactionsReturnSelfTransfer: null,
    accountcardOverviewBlockAccount: null,
    accountcardOverviewCloseAccount: null,
    accountcardOverviewCreateRecipient: null,
    accountcardDirectDebitsEnable: null,
    accountcardScaDevicesDelete: null,
    accountcardScheduledPaymentsCancelSchedule: null,
    accountcardScheduledPaymentsSkipNextPayment: null,
    accountcardDirectDebitsCancelMandate: null,
    accountcardOverviewCardShipment: null,
  }),
  [APPS.administration]: keyMirror({
    groupsEdit: null,
    usersEdit: null,
  }),
  [APPS.reports]: keyMirror({
    mcReportGenerate: null,
  }),
  [APPS.card_reconciliation]: keyMirror({
    authorizationsAdjust: null,
    clearingsMatch: null,
  }),
  [APPS.ops_transfers]: keyMirror({
    requestsInitiate: true,
    requestsPreApproveAndAuthorize: true,
  }),
  [APPS.mass_actions]: keyMirror({
    actionsPublishPaymentEvents: null,
  }),
};
